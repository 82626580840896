<template>
	<div class="about">
		<carousel
            :nav = true
            :centerMode = true
            :autoPlay = true
            :playSpeed = 8000
            :dtImg = banner
        />
		
        <div class="-mt-2 md:mt-0 mb-9">
            <div class="bg-grays-100 md:h-16 text-grays-400 w-full h-14 overflow-x-scroll">
               
                <div class="flex justify-center items-center h-full lg:px-32 max-w-5xl m-auto">
                    <router-link 
                        :to="{ name: 'Karir'}" :exact="true"
                        class="px-2 mx-3 capitalize font-pop text-xs lg:text-sm  text-grays-400 font-semibold w-80 h-full flex items-center cursor-pointer justify-center border-b-4 border-solid border-grays-200 hover:border-primary hover:text-primary flex-wrap"
                        :class="{'active': exactActive}"
                    >
                        {{ $t('karir.our') }}
                    </router-link>
                     <!-- <router-link 
                        :to="{ name: 'Bersama'}"
                        class="px-2 mx-3 capitalize font-pop text-xs lg:text-sm  text-grays-400 font-semibold w-80 h-full flex items-center cursor-pointer justify-center border-b-4 border-solid border-grays-200 hover:border-primary hover:text-primary flex-wrap"
                    >
                        karir bersama PT Dharma
                    </router-link>
                     <router-link 
                        :to="{ name: 'SDM'}"
                        class="px-2 mx-3 capitalize font-pop text-xs lg:text-sm  text-grays-400 font-semibold w-80 h-full flex items-center cursor-pointer justify-center border-b-4 border-solid border-grays-200 hover:border-primary hover:text-primary flex-wrap"
                    >
                        sumber daya manusia
                    </router-link> -->
                    
                </div>

            </div>
        </div>
        <router-view class="my-4 p-3 lg:px-32 min-h-10 mb-40">

        </router-view>
	</div>
</template>

<script>

import carousel from "@/components/carousel.vue";
export default {
	name: "About",
	components: {
		carousel
	},
	data() {
		return {
			hdtImg : [
				{
					"name":"banner 4", 
					"src":"https://images.unsplash.com/photo-1521791136064-7986c2920216?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80"
				},
			],
            tabMenu:[
                {
                    name : 'lowongan kami',
                    path : 'karir'
                },
                {
                    name : 'karir bersama PT Dharma',
                    path : 'karir/bersama'
                },
                {
                    name : 'sumber daya manusia',
                    path : 'karir/sdm'
                }
            ],
            tabActive: 0,
            exactActive : false
		}
	},

    mounted() {
        this.getAllDataInKarir()
    },
 
    methods: {
        handleTab(i){
            this.tabActive = i
        },
        async getAllDataInKarir() {
            try {
                await this.$store.dispatch('karir/getCompany')
                await this.$store.dispatch('karir/getListLoker')
            
            } catch (error) {
                console.log(error)
            }
        }
    },
    computed: {
        banner(){
			return this.$store.getters['image/imgWtFilter']('career_banner')
		}
    },
    watch:{
    '$route' (to, from){
        if(to.name === 'Karir' || to.name === 'List' || to.name === 'View' || to.name === 'Apply'){
            return this.exactActive = true
        }else{
            return this.exactActive = false
        }
    }
},

}
</script>
<style scoped>
    .active{
         @apply bg-primary text-grays-50 border-primary hover:text-grays-50;
    }
    
</style>
